var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.productLookup)?_c('div',[_c('SimpleTable',{attrs:{"columns":_vm.performanceColumns,"data":_vm.data.performance,"highlight":_vm.highlight,"localize-headers":""},scopedSlots:_vm._u([{key:"header-satisfactionRegardingEfficacy",fn:function(){return [_c('DlgInputDescription',{attrs:{"name":"satisfaction","has-description":""}})]},proxy:true},{key:"header-wasTestedForEfficacy",fn:function(){return [_c('DlgInputDescription',{attrs:{"name":"effectivenessTest","has-description":""}})]},proxy:true},{key:"productId",fn:function(ref){
var productId = ref.row.productId;
return [_vm._v("\n      "+_vm._s(_vm.productLookup[productId].name)+"\n    ")]}},{key:"pest",fn:function(ref){
var row = ref.row;
return [(_vm.editBuffer && _vm.editBuffer.productId === row.productId)?_c('div',[_c('TextInput',{attrs:{"name":"pest","required":""},model:{value:(_vm.editBuffer.pest),callback:function ($$v) {_vm.$set(_vm.editBuffer, "pest", $$v)},expression:"editBuffer.pest"}})],1):_vm._e()]}},{key:"isProphylactic",fn:function(ref){
var row = ref.row;
return [(_vm.editBuffer && _vm.editBuffer.productId === row.productId)?_c('div',_vm._l(([false, true]),function(isProphylactic){return _c('BsRadio',{key:isProphylactic,attrs:{"value":isProphylactic,"inline":""},model:{value:(_vm.editBuffer.isProphylactic),callback:function ($$v) {_vm.$set(_vm.editBuffer, "isProphylactic", $$v)},expression:"editBuffer.isProphylactic"}},[_c('IxRes',[_vm._v("dlgCert.ecology.plantProtection.isProphylactic_"+_vm._s(isProphylactic))])],1)}),1):_c('div',[_c('IxRes',[_vm._v("dlgCert.ecology.plantProtection.isProphylactic_"+_vm._s(row.isProphylactic))])],1)]}},{key:"severityOrReason",fn:function(ref){
var row = ref.row;
return [(_vm.editBuffer && _vm.editBuffer.productId === row.productId)?_c('div',[(_vm.editBuffer.isProphylactic)?_c('div',[_c('TextInput',{attrs:{"name":"reason","required":""},model:{value:(_vm.editBuffer.reason),callback:function ($$v) {_vm.$set(_vm.editBuffer, "reason", $$v)},expression:"editBuffer.reason"}})],1):_c('div',_vm._l((['low', 'medium', 'high']),function(severity){return _c('BsRadio',{key:severity,attrs:{"value":severity,"inline":""},model:{value:(_vm.editBuffer.severity),callback:function ($$v) {_vm.$set(_vm.editBuffer, "severity", $$v)},expression:"editBuffer.severity"}},[_c('IxRes',[_vm._v("dlgCert.ecology.plantProtection.severity_"+_vm._s(severity))])],1)}),1)]):_c('div',[(row.isProphylactic)?_c('span',[_vm._v("\n          "+_vm._s(row.reason)+"\n        ")]):_c('span',[_c('IxRes',[_vm._v("dlgCert.ecology.plantProtection.severity_"+_vm._s(row.severity))])],1)])]}},{key:"wasTestedForEfficacy",fn:function(ref){
var row = ref.row;
return [(_vm.editBuffer && _vm.editBuffer.productId === row.productId)?_c('div',{staticClass:"efficacy-check"},[_c('BsCheckbox',{model:{value:(_vm.editBuffer.wasTestedForEfficacy),callback:function ($$v) {_vm.$set(_vm.editBuffer, "wasTestedForEfficacy", $$v)},expression:"editBuffer.wasTestedForEfficacy"}},[_c('IxRes',[_vm._v("dlgCert.ecology.plantProtection.effectivenessTest")])],1),(_vm.editBuffer.wasTestedForEfficacy)?_c('div',{staticClass:"testing-type"},[_c('BsRadio',{staticClass:"test-radio-box",attrs:{"value":"controlGroup"},model:{value:(_vm.editBuffer.testMethod),callback:function ($$v) {_vm.$set(_vm.editBuffer, "testMethod", $$v)},expression:"editBuffer.testMethod"}},[_c('IxRes',[_vm._v("dlgCert.ecology.plantProtection.controlGroup")])],1),_c('BsRadio',{attrs:{"value":"insectTrap"},model:{value:(_vm.editBuffer.testMethod),callback:function ($$v) {_vm.$set(_vm.editBuffer, "testMethod", $$v)},expression:"editBuffer.testMethod"}},[_c('IxRes',[_vm._v("dlgCert.ecology.plantProtection.insectTrap")])],1)],1):_vm._e()],1):_c('div',[_c('IxRes',[_vm._v("dlgCert.ecology.plantProtection."+_vm._s(row.wasTestedForEfficacy ? 'effectivenesTestDone' : 'noEffectivenesTest'))]),(row.testMethod)?_c('span',[_vm._v("\n          ("),_c('IxRes',[_vm._v("dlgCert.ecology.plantProtection."+_vm._s(row.testMethod))]),_vm._v(")\n        ")],1):_vm._e()],1)]}},{key:"satisfactionRegardingEfficacy",fn:function(ref){
var row = ref.row;
var value = ref.value;
return [(_vm.editBuffer && _vm.editBuffer.productId === row.productId)?_c('div',[_c('VueSelectize',{attrs:{"name":"satisfactionRegardingEfficacy","options":_vm.satisfactionOptions,"fields":_vm.satisfactionFields,"placeholder":_vm.$i18n.translate('dlgCert.ecology.plantProtection.satisfaction_null'),"allow-empty":!_vm.editBuffer.wasTestedForEfficacy},model:{value:(_vm.editBuffer.satisfactionRegardingEfficacy),callback:function ($$v) {_vm.$set(_vm.editBuffer, "satisfactionRegardingEfficacy", $$v)},expression:"editBuffer.satisfactionRegardingEfficacy"}})],1):_c('span',[_c('IxRes',[_vm._v("dlgCert.ecology.plantProtection."+_vm._s(("satisfaction_" + value)))])],1)]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [(_vm.editBuffer && _vm.editBuffer.productId === row.productId)?_c('div',[_c('FontAwesomeIcon',{staticClass:"icon-add",attrs:{"icon":_vm.icons.check},on:{"click":function($event){return _vm.save(row)}}}),_c('FontAwesomeIcon',{staticClass:"icon-cancel",attrs:{"icon":_vm.icons.times},on:{"click":_vm.cancel}})],1):_c('div',[_c('FontAwesomeIcon',{staticClass:"icon",attrs:{"icon":_vm.icons.edit},on:{"click":function($event){return _vm.edit(row)}}})],1)]}}],null,false,3769777167)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }