<template>
  <div>
    <ProgressCircle :value="percentValue" />
    <IxRes :context="{percent: $i18n.format(percentValue, 'percent')}">dlgCert.{{ pillar }}.{{ $route.name }}.labels.{{ name }}</IxRes>
  </div>
</template>

<script>
import ProgressCircle from '@dlg-cert/components/widgets/ProgressCircle'

import {getPillar} from '@dlg-cert/helpers'

export default {
  components: {
    ProgressCircle
  },
  props: {
    data: Object,
    showPercentOf: String,
    name: String,
    hasDescription: Boolean
  },
  computed: {
    pillar () {
      return getPillar(this.$route)
    },
    value () {
      return this.data[this.name]
    },
    percentValue () {
      const referenceValue = this.data.statistics[this.showPercentOf]

      if (this.value === null || this.value === undefined || !referenceValue) return null

      return this.value / referenceValue
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
