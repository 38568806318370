<template lang="html">
  <div v-if="productLookup">
    <SimpleTable
      :columns="performanceColumns"
      :data="data.performance"
      :highlight="highlight"
      localize-headers
    >
      <template #header-satisfactionRegardingEfficacy>
        <DlgInputDescription name="satisfaction" has-description />
      </template>
      <template #header-wasTestedForEfficacy>
        <DlgInputDescription name="effectivenessTest" has-description />
      </template>
      <template #productId="{row: {productId}}">
        {{ productLookup[productId].name }}
      </template>
      <template #pest="{row}">
        <div v-if="editBuffer && editBuffer.productId === row.productId">
          <TextInput v-model="editBuffer.pest" name="pest" required />
        </div>
      </template>
      <template #isProphylactic="{row}">
        <div v-if="editBuffer && editBuffer.productId === row.productId">
          <BsRadio
            v-for="isProphylactic in [false, true]"
            :key="isProphylactic" v-model="editBuffer.isProphylactic"
            :value="isProphylactic" inline
          >
            <IxRes>dlgCert.ecology.plantProtection.isProphylactic_{{ isProphylactic }}</IxRes>
          </BsRadio>
        </div>
        <div v-else>
          <IxRes>dlgCert.ecology.plantProtection.isProphylactic_{{ row.isProphylactic }}</IxRes>
        </div>
      </template>
      <template #severityOrReason="{row}">
        <div v-if="editBuffer && editBuffer.productId === row.productId">
          <div v-if="editBuffer.isProphylactic">
            <TextInput v-model="editBuffer.reason" name="reason" required />
          </div>
          <div v-else>
            <BsRadio
              v-for="severity in ['low', 'medium', 'high']"
              :key="severity" v-model="editBuffer.severity"
              :value="severity" inline
            >
              <IxRes>dlgCert.ecology.plantProtection.severity_{{ severity }}</IxRes>
            </BsRadio>
          </div>
        </div>
        <div v-else>
          <span v-if="row.isProphylactic">
            {{ row.reason }}
          </span>
          <span v-else>
            <IxRes>dlgCert.ecology.plantProtection.severity_{{ row.severity }}</IxRes>
          </span>
        </div>
      </template>
      <template #wasTestedForEfficacy="{row}">
        <div v-if="editBuffer && editBuffer.productId === row.productId" class="efficacy-check">
          <BsCheckbox v-model="editBuffer.wasTestedForEfficacy">
            <IxRes>dlgCert.ecology.plantProtection.effectivenessTest</IxRes>
          </BsCheckbox>
          <div v-if="editBuffer.wasTestedForEfficacy" class="testing-type">
            <BsRadio v-model="editBuffer.testMethod" class="test-radio-box" value="controlGroup">
              <IxRes>dlgCert.ecology.plantProtection.controlGroup</IxRes>
            </BsRadio>
            <BsRadio v-model="editBuffer.testMethod" value="insectTrap">
              <IxRes>dlgCert.ecology.plantProtection.insectTrap</IxRes>
            </BsRadio>
          </div>
        </div>
        <div v-else>
          <IxRes>dlgCert.ecology.plantProtection.{{ row.wasTestedForEfficacy ? 'effectivenesTestDone' : 'noEffectivenesTest' }}</IxRes>
          <span v-if="row.testMethod">
            (<IxRes>dlgCert.ecology.plantProtection.{{ row.testMethod }}</IxRes>)
          </span>
        </div>
      </template>
      <template #satisfactionRegardingEfficacy="{row, value}">
        <div v-if="editBuffer && editBuffer.productId === row.productId">
          <VueSelectize
            v-model="editBuffer.satisfactionRegardingEfficacy"
            name="satisfactionRegardingEfficacy"
            :options="satisfactionOptions"
            :fields="satisfactionFields"
            :placeholder="$i18n.translate('dlgCert.ecology.plantProtection.satisfaction_null')"
            :allow-empty="!editBuffer.wasTestedForEfficacy"
          />
        </div>
        <span v-else>
          <IxRes>dlgCert.ecology.plantProtection.{{ `satisfaction_${value}` }}</IxRes>
        </span>
      </template>
      <template #actions="{row}">
        <div v-if="editBuffer && editBuffer.productId === row.productId">
          <FontAwesomeIcon class="icon-add" :icon="icons.check" @click="save(row)" />
          <FontAwesomeIcon class="icon-cancel" :icon="icons.times" @click="cancel" />
        </div>
        <div v-else>
          <FontAwesomeIcon class="icon" :icon="icons.edit" @click="edit(row)" />
        </div>
      </template>
    </SimpleTable>
  </div>
</template>

<script>
import axios from 'axios'
import {faLock, faTrashAlt, faEdit, faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import DlgInputDescription from './DlgInputDescription'
import TextInput from '@components/forms/TextInput'
import BsRadio from '@components/bootstrap/BsRadio'
import BsCheckbox from '@components/bootstrap/BsCheckbox'

import SimpleTable from '@components/table/SimpleTable'

import FormPartMixin from '@components/forms/FormPartMixin'
import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    VueSelectize,
    DlgInputDescription,
    FontAwesomeIcon,
    TextInput,
    SimpleTable,
    BsRadio,
    BsCheckbox
  },
  mixins: [
    FormPartMixin
  ],
  props: {
    data: Object
  },
  data () {
    return {
      editBuffer: null,
      productLookup: null
    }
  },
  computed: {
    satisfactionOptions () {
      return ['low', 'medium', 'high']
    },
    satisfactionFields () {
      return {
        text: satisfaction => this.$i18n.translate(`dlgCert.ecology.plantProtection.satisfaction_${satisfaction}`),
        value: satisfaction => satisfaction
      }
    },
    icons () {
      return {
        locked: faLock,
        remove: faTrashAlt,
        edit: faEdit,
        check: faCheckCircle,
        times: faTimesCircle
      }
    },
    performanceColumns  () {
      const prefix = 'dlgCert.ecology.plantProtection.protection.'
      return {
        productId: prefix + 'protections',
        pest: prefix + 'vermin',
        isProphylactic: prefix + 'isProphylactic',
        severityOrReason: prefix + 'severityOrReason',
        wasTestedForEfficacy: prefix + 'wasTestedForEfficacy',
        satisfactionRegardingEfficacy: prefix + 'satisfactionRegardingEfficacy',
        actions: prefix + 'actions'
      }
    }
  },
  methods: {
    edit (row) {
      this.cancel()
      this.currentlyEditedLine = row.id
      this.editBuffer = {
        ...row,
        initialized: true
      }
    },
    cancel () {
      this.editBuffer = null
    },
    async save (row) {
      this.validate()

      await this.$nextTick()

      if (this.state !== 'success') return

      const data = {
        ...this.editBuffer
      }

      this.editBuffer = null
      this.$emit('update', data)
    },
    highlight (row) {
      return !row.initialized ? 'warning' : null
    }
  },
  watch: {
    'editBuffer' (editBuffer) {
      if (editBuffer === null) {
        this.fieldStates = {}
      }
    },
    'editBuffer.isProphylactic' (value, oldValue) {
      if (value === undefined || oldValue === undefined) return

      if (value) {
        this.editBuffer.reason = ''
        this.editBuffer.severity = null
      } else {
        this.editBuffer.reason = null
        this.$delete(this.fieldStates.reason)
        this.editBuffer.severity = 'medium'
      }
    },
    'editBuffer.wasTestedForEfficacy' (value, oldValue) {
      if (value === undefined || oldValue === undefined) return

      if (value) {
        this.editBuffer.testMethod = 'controlGroup'
        this.editBuffer.satisfactionRegardingEfficacy = 'high'
      } else {
        this.editBuffer.testMethod = null
        this.editBuffer.satisfactionRegardingEfficacy = null
      }
    }
  },
  async created () {
    const [globalProducts, customProducts] = await Promise.all([
      axios.get('/api/v2/master-data/products/global').then(response => response.data),
      axios.get(`/api/v2/master-data/products/custom/${this.$route.params.orgUnitId}`).then(response => response.data)
    ])

    this.productLookup = {
      ...globalProducts,
      ...customProducts
    }
  }
}
</script>

<style lang="scss">
.icon {
  color: gray;
}
.icon:hover, .icon-add:hover, .icon-cancel:hover {
  cursor: pointer;
}
.icon-cancel{
  color: red;
}
.icon-add{
  color: green;
}
.header {
  display: flex;
}
.italic-font {
  font-style: italic;
}
.efficacy-check {
  display: flex;
  flex-direction: column;
  .testing-type {
    display: flex;
    .test-radio-box {
      margin-right: 4px;
    }
  }
}
</style>
