<template lang="html">
  <transition mode="out-in">
    <span v-if="loading">Loading...</span>
    <div v-else class="ecology-plantProtection">
      <DlgGridHeader>
        <IxRes>dlgCert.ecology.plantProtection.headers.intervalOfCrop</IxRes>
      </DlgGridHeader>
      <div>
        <IxRes>dlgCert.ecology.plantProtection.info.intervalOfCrop</IxRes>
      </div>
      <SimpleTable
        :columns="columns" :data="filteredCultivationPauses"
        class="cultivation-phase-table"
        localize-headers
      >
        <template #cropUsageId="{row}">
          <IxRes>masterData.crops.usage.{{ row.cropUsageId }}_name</IxRes>
        </template>
        <template #pausedYears="{row}">
          <div v-if="row.isLocked">
            {{ row.pausedYears === null ? '' : row.pausedYears }}
            <FontAwesomeIcon class="lock-icon" :icon="icons.locked" />
          </div>
          <div v-else>
            <IxButton @click="decrementCultivationPause(row)">
              -
            </IxButton>
            <span class="cultivation-pause">{{ row.pausedYears === null ? '?' : row.pausedYears }}</span>
            <IxButton @click="incrementCultivationPause(row)">
              +
            </IxButton>
          </div>
        </template>
      </SimpleTable>
      <!-- <a @click.prevent="showLockedCultivationPauses = !showLockedCultivationPauses">
        <IxRes>dlgCert.ecology.plantProtection.showLockedCultivationPauses</IxRes>
      </a> -->
      <CheckboxLine
        class="use-forecast-models" :data="data"
        name="useForecastModels" @input="update"
      />
      <div class="forecast-data">
        <span class="hint">
          <IxRes>dlgCert.ecology.hints.forecast</IxRes>
        </span>
        <TextInput
          v-model="data.descriptionForecastModels"
          :disabled="!data.useForecastModels"
          class="description-input"
          multiline
          @input="update({value: $event, name: 'descriptionForecastModels'})"
        />
      </div>
      <CheckboxLine :data="data" name="insecticidesThreshold" @input="update" />
      <div class="forecast-data">
        <span class="hint">
          <IxRes>dlgCert.ecology.hints.threshold</IxRes>
        </span>
        <TextInput
          v-model="data.descriptionThreshold"
          :disabled="!data.insecticidesThreshold"
          class="description-input"
          multiline
          @input="update({value: $event, name: 'descriptionThreshold'})"
        />
      </div>

      <br>
      <CheckboxLine
        v-for="name in ['weedControlCrop', 'weedControlHarrow', 'stubbleRemoval', 'mulching']" :key="name"
        :data="data" :name="name"
      />
      <CheckboxLine
        v-for="name in ['coloradoBeetle', 'sclerotinia', 'maiszünsler']" :key="name"
        :data="data" :name="name"
        @input="update"
      />
      <EcologyInputGrid class="other-natural">
        <EcologyInputLine
          :data="data" name="otherNatural"
          unit=""
          @input="checkBetween"
        />
      </EcologyInputGrid>
      <br>

      <CheckboxLine :data="data" name="confirmInput" @input="update">
        <DlgInputDescription name="confirmInput" :context="withoutPlantProtectantsContext" />
      </CheckboxLine>
      <DlgProgressCircleLine
        v-for="name in ['withoutHerbicides', 'withoutFungicides', 'withoutInsecticides']" :key="name"
        :data="data" :name="name"
        show-percent-of="total"
        class="progress-style"
      />
      <CheckboxLine :data="data" name="useLossReducingNozzles" @input="update" />

      <div class="date-time">
        <DlgGridHeader>
          <IxRes>dlgCert.ecology.plantProtection.labels.earlySowing</IxRes>
        </DlgGridHeader>
        <VueDateTimePicker
          v-model="data.earlySowingWinterSoftWheat" name="Winterweichweizen"
          iso dont-use-current-date
          @input="update({value: $event, name: 'earlySowingWinterSoftWheat'})"
        >
          <template #label>
            <IxRes>
              dlgCert.ecology.plantProtection.labels.winterSoftWheat
            </IxRes>
          </template>
          <template #description>
            <IxRes slot="description">
              dlgCert.ecology.plantProtection.labels.winterSoftWheatDescription
            </IxRes>
          </template>
        </VueDateTimePicker>
        <VueDateTimePicker
          v-model="data.earlySowingWinterRape" name="Winterraps"
          iso dont-use-current-date
          @input="update({value: $event, name: 'earlySowingWinterRape'})"
        >
          <template #label>
            <IxRes>dlgCert.ecology.plantProtection.labels.winterRape</IxRes>
          </template>
          <template #description>
            <IxRes>dlgCert.ecology.plantProtection.labels.winterRapeDescription</IxRes>
          </template>
        </VueDateTimePicker>
      </div>

      <EcologyInputGrid class="fuel-grid">
        <DlgGridHeader>
          <IxRes>dlgCert.ecology.plantProtection.labels.fuelConsumptionCurrentYear</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          v-for="name in ['diesel', 'bioDiesel', 'rapeOil', 'petrol']" :key="name"
          :data="data" :name="name"
          unit="l"
          @input="update"
        />
      </EcologyInputGrid>

      <DlgGridHeader>
        <IxRes>dlgCert.ecology.plantProtection.headers.vermin</IxRes>
      </DlgGridHeader>
      <div>
        <IxRes>dlgCert.ecology.plantProtection.info.vermin</IxRes>
      </div>
      <PlantProtectionPerformanceTable :data="data" @update="updatePerformanceRow" />
    </div>
  </transition>
</template>

<script>
import {notifications} from 'src/js/infrastructure'

import {mapState} from 'vuex'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import DlgProgressCircleLine from './elements/DlgProgressCircleLine'
import DlgInputDescription from './elements/DlgInputDescription'
import CheckboxLine from './elements/CheckboxLine'
import DlgGridHeader from './elements/DlgGridHeader'
import PlantProtectionPerformanceTable from './elements/PlantProtectionPerformanceTable'
import EcologyInputLine from './elements/EcologyInputLine'
import EcologyInputGrid from './elements/EcologyInputGrid'

import TextInput from '@components/forms/TextInput'
import IxButton from '@components/IxButton'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'

import SimpleTable from '@components/table/SimpleTable'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'
import {faLock} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    EcologyInputGrid,
    EcologyInputLine,
    PlantProtectionPerformanceTable,
    FontAwesomeIcon,
    DlgProgressCircleLine,
    DlgInputDescription,
    TextInput,
    VueDateTimePicker,
    CheckboxLine,
    SimpleTable,
    DlgGridHeader,
    IxButton
  },
  mixins: [
    ApiMixin
  ],
  data () {
    return {
      showLockedCultivationPauses: false
    }
  },
  computed: {
    icons () {
      return {
        locked: faLock
      }
    },
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/ecology/${this.$route.params.year}/plant-protection`
    },
    columns () {
      return {
        fieldName: 'dlgCert.ecology.plantProtection.table.header.fieldName',
        cropUsageId: 'dlgCert.ecology.plantProtection.table.header.cultivation',
        pausedYears: 'dlgCert.ecology.plantProtection.table.header.intervalOfCropCount'
      }
    },
    filteredCultivationPauses () {
      if (this.showLockedCultivationPauses) {
        return this.data.cultivationPauses
      } else {
        return this.data.cultivationPauses.filter(x => x.isLocked === false)
      }
    },
    ...mapState('masterData', [
      'cropUsages'
    ]),
    withoutPlantProtectantsContext () {
      const value = this.data.withoutPlantProtectants
      const referenceValue = this.data.statistics.total

      const percent = (value === null || value === undefined || !referenceValue)
        ? null
        : value / referenceValue

      return {
        percent: percent !== null ? this.$i18n.format(percent, 'percent') : '? %'
      }
    }
  },
  methods: {
    decrementCultivationPause (row) {
      const {pausedYears} = row

      this.updateCultivationPhase(row, pausedYears === null ? 0 : pausedYears - 1)
    },
    incrementCultivationPause (row) {
      const {pausedYears} = row

      this.updateCultivationPhase(row, pausedYears === null ? 1 : pausedYears + 1)
    },
    checkBetween (event) {
      if (event.value >= 0 && event.value <= 100) {
        this.update(event)
      }
    },
    async updateCultivationPhase (row, newPause) {
      const {pausedYears: previousPause} = row

      row.pausedYears = newPause

      try {
        await this.api.put(`phases/${row.cultivationId}`, row)
      } catch (error) {
        console.error(error)
        notifications.error(this.$i18n.translate('dlgCert.ecology.plantProtection.notifications.failedToUpdateCultivationPhaseRow'))
        row.pausedYears = previousPause
      }
    },
    async updatePerformanceRow (data) {
      const previousData = this.data.performance.find(x => x.productId === data.productId)
      try {
        this.data.performance.splice(this.data.performance.findIndex(x => x.productId === data.productId), 1, data)
        await this.api.put(`treatment/${data.productId}`, data)
      } catch (error) {
        console.error(error)
        notifications.error(this.$i18n.translate('dlgCert.ecology.plantProtection.notifications.failedToUpdatePerformanceRow'))
        this.data.performance.splice(this.data.performance.findIndex(x => x.productId === data.productId), 1, previousData)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
a:hover {
  cursor: pointer;
}
.cultivation-phase-table {
  margin-bottom: 5px;

  .cultivation-pause {
    margin: 0 0.5em;
  }
}
.use-forecast-models {
  margin-top: 10px;
}
.v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-active, .v-leave-active {
  transition: opacity 0.3s;
}
.forecast-data {
  padding-left: 20px;
  margin-bottom: 8px;

  .hint {
    color: #b4b4b4;
  }
  .description-input {
    width: 45%;
  }
}
.progress-style {
  padding-left: 20px;
}

.fuel-grid {
  width: 30%;
}
.other-natural {
  width: 30%;
}
.date-time {
  width: 15%;
}
</style>
